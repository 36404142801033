<template>
    <b-overlay :show="loading">
        <!-- 1 -->
        <b-card no-body class="mb-4">
            <h3 class="p-3">Bank List</h3>
            <!-- Header -->
            <b-card-body class="p-3">
                <div class="d-flex justify-content-between mb-2">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button
                            variant="primary"
                            class="mr-2"
                            @click="submitScreenings"
                            :disabled="selected.length === 0"
                            >Submit selected to Screening</b-button
                        >
                    </div>
                </div>
                <b-table-simple show-empty responsive small>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th>
                                <b-form-checkbox v-model="selectAll" @change="selectAllHandler"></b-form-checkbox>
                            </b-th>
                            <b-th v-for="head in fields" :key="head.key">
                                {{ head.label }}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="item in screenings" :key="item.id">
                            <b-th>
                                <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
                            </b-th>
                            <b-td v-for="field in fields" :key="field.key">
                                <span v-if="field.key == 'submitedToReviewDate' || field.key == 'birthDate'">
                                    {{ $formatDate(item[field.key]) }}
                                </span>
                                <span v-else>{{ item[field.key] }}</span>
                            </b-td>
                            <!-- <b-th></b-th> -->
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-card-body>
            <!-- End Section -->
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'screening-requests',

    data: () => ({
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'No',
            },
            {
                key: 'submitedToReviewDate',
                label: 'Date submitted',
            },
            {
                key: 'customerFullName',
                label: 'Full Name',
            },
            {
                key: 'address',
                label: 'Address',
            },
            {
                key: 'nationalID',
                label: 'National ID',
            },
            {
                key: 'birthDate',
                label: 'Birth Date',
            },
            {
                key: 'bankDescription',
                label: 'Employer(Banks)',
            },
            // {
            //     key: 'action',
            //     label: 'Action',
            // },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],

        loading: true,
        screenings: [],
        selected: [],
        selectAll: false,
    }),
    components: {},
    methods: {
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('customer/screening/get_pendingRequests');
            this.screenings = response ?? [];
            this.loading = false;
        },
        selectAllHandler() {
            if (this.selectAll) {
                this.selected = this.screenings.map((screening) => screening.id);
            } else {
                this.selected = [];
            }
        },
        async submitScreenings() {
            this.loading = true;
            await this.$store.dispatch('customer/screening/submit_toPendingVerification', this.selected);
            await this.getData();
            this.loading = false;
            this.selected = [];
            this.selectAll = false;
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'authorizations/getAuthorizations',
        }),
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
